import React, { useEffect } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql, Link } from "gatsby"
import Glide from "@glidejs/glide"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../graphql/fragments"

import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs"
import { Cover } from "../../components/cover/cover"
import { CoverMain } from "../../components/cover-main/cover-main"
import { CoverFeatured } from "../../components/cover-featured/cover-featured"
import { Card } from "../../components/card/card"
import { HeadingBasic } from "../../components/heading/heading"
import { Button } from "../../components/buttons/buttons"

import {
  WpGroup,
  WpIconlist,
  Stats,
  Carousel,
} from "../../components/utils/render-functions"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const EducationPatronage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "img_development-main.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        pageBy(uri: "sustainability/education-patronage") {
          id
          title
          slug
          featuredImage {
            sourceUrl
            mediaItemUrl
            title
            caption
          }
          blocks {
            name
            ...AcfGalleryBlock
            ...CoreGroupBlock
            ...CoreParagraphBlock
            ...CoreHeadingBlock
            ...CoreCoverBlock
            ...CoreShortcodeBlock
            ...CoreEmbedYoutube
            ...CoreButtonBlock
          }
          childPages(where: {orderby: {field: MENU_ORDER, order: ASC}}) {
            nodes {
              title
              slug
              excerpt
              menuOrder
              featuredImage {
                sourceUrl
                mediaItemUrl
                title
                caption
              }
            }
          }
          seo {
            title
            twitterTitle
            twitterDescription
            metaDesc
            opengraphTitle
            opengraphDescription
          }
        }
      }
    }
  `)

  useEffect(() => {
    const gallery = document.querySelectorAll("#gallery")

    gallery.forEach(item => {
      const slider = new Glide(item, {
        perView: 3,
        gap: 10,
        startAt: 0,
        breakpoints: {
          992: {
            perView: 1,
          },
        },
      })

      slider.mount()
    })
  }, [])

  useEffect(() => {
    const thisElement = document.querySelector(".py-main .container")
    thisElement.classList.remove("container-md")
  }, [])

  const wordPress = data.wordPress.pageBy.blocks
  const seo = data.wordPress.pageBy.seo
  const cover =
    wordPress.find(x => x.name === "core/cover") === wordPress[0] &&
    wordPress[0]
  const featuredImage = data?.wordPress?.pageBy?.featuredImage?.mediaItemUrl

  return(
    <Layout currentLocation={location.uri} lang="EN">
      <SEO 
        img={featuredImage}
        description={seo?.opengraphDescription}
        title={seo?.title}
        fbImg={featuredImage}
        fbTitle={seo?.opengraphTitle || seo?.title}
        fbDescription={seo?.opengraphDescription}
        twitterImg={featuredImage}
        twitterTitle={seo?.twitterTitle || seo?.title}
        twitterDescription={seo?.twitterDescription || seo?.opengraphDescription}
        url={location?.href}
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      <CoverMain
        h1={data.wordPress.pageBy.title || "Education Patronage"}
        img={
          cover ? cover.attributes.url : data.imgCoverMain.childImageSharp.fluid
        }
        alt="Education Patronage"
        className="cover-md"
      >
        <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
      </CoverMain>

      {wordPress.map((item, i) => {
        switch (item.name) {
          case "core/heading":
            return (
              <div key={`heading-${i}`} className="container">
                <div className={i === 0 ? "py-main" : ""}>
                  <HeadingBasic h2={item.attributes.content} />
                </div>
              </div>
            )

          case "core-embed/youtube":
            return (
              <div className="py-main">
                <Cover
                  variant="basic"
                  theme="dark"
                  img={null}
                  imgHeight="h-400px h-md-500px w-100"
                  // imgOverlay="40"
                  className="cover cover-full-top"
                  type="video-popup"
                  videoSrc={item.attributes && item.attributes.url}
                  type={{ name: "core-embed/youtube" }}
                  audio={false}
                />
              </div>
            )
          case "core/buttons":
            if (
              item.innerBlocks[0].attributes.url &&
              item.innerBlocks[0].attributes.url.split(".").length > 1
            ) {
              return (
                <div key={`button=${i}`} className="container">
                  <div className={i === 0 ? "py-main" : "pb-main"}>
                    <HeadingBasic>
                      <a
                        href={item.innerBlocks[0].attributes.url}
                        target="_blank"
                        className="btn btn-link fx-underline"
                        rel="noopener noreferrer"
                        key={`${item.innerBlocks[0].attributes.url}-${i}`}
                      >
                        {item.innerBlocks[0].attributes.text} &nbsp;
                        <i className="far fa-external-link"></i>
                      </a>
                    </HeadingBasic>
                  </div>
                </div>
              )
            } else {
              return (
                <div key={`button-${i}`} className="container">
                  <Link
                    key={`${item.innerBlocks[0].attributes.url}-${i}`}
                    to={item.innerBlocks[0].attributes.url}
                    className="btn btn-link fx-underline"
                  >
                    {item.innerBlocks[0].attributes.text}
                  </Link>
                </div>
              )
            }
          case "core/paragraph":
            return (
              <div key={`headingbasic-${i}`} className="py-main sus-detail-paragraph">
                <div className="container">
                  <HeadingBasic text={[item.attributes.content]} />
                </div>
              </div>
            )
          case "core/group":
            return (
              <div key={`${item.name}-${i}`} className="py-main">
                <WpGroup data={item} />
              </div>
            )
          case "acf/iconlist":
            return (
              <div
                key={`iconlist-${i}`}
                className={i === 0 ? "container py-main" : "container pb-main"}
              >
                <WpIconlist data={item} />
              </div>
            )
          case "acf/labelvalues":
            return (
              <div key={`${item.name}-${i}`} className="pb-main">
                <Stats data={item} />
              </div>
            )
          case "core/cover":
            if (i > 0) {
              return (
                <div key={`featured-${i}`} className="pb-main">
                  <CoverFeatured
                    img={
                      item.attributes.url || data.featured.childImageSharp.fluid
                    }
                    title={
                      item.innerBlocks[0] !== undefined &&
                      item.innerBlocks[0].attributes.content
                    }
                    text={
                      item.innerBlocks[1] !== undefined &&
                      item.innerBlocks[1].attributes.content
                    }
                    btnText={
                      item.innerBlocks[2] !== undefined &&
                      item.innerBlocks[2].innerBlocks[0].attributes.text
                    }
                    btnLink={
                      item.innerBlocks[2] !== undefined &&
                      item.innerBlocks[2].innerBlocks[0].attributes.url
                    }
                  />
                </div>
              )
            } else {
              return <div key={`featured-${i}`}></div>
            }
          case "acf/acfgallery":
            return (
              <div key={`gallery-${i}`}>
                <Carousel data={item} />
              </div>
            )

          case "core-embed/youtube":
            return (
              <div className="py-main">
                <Cover
                  variant="basic"
                  theme="dark"
                  img={null}
                  imgHeight="h-400px h-md-500px w-100"
                  // imgOverlay="40"
                  className="cover cover-full-top"
                  type="video-popup"
                  videoSrc={item.attributes.url}
                  type={{ name: "core-embed/youtube" }}
                  audio={false}
                />
              </div>
            )
          case "core/shortcode":
            if (item.attributes.text === "section-subpage") {
              return (
                <section key={`section-subpage-${i}`} className="pb-main">
                  <div className="container">
                    {data.wordPress.pageBy.childPages.nodes.map(
                      (childPage, index) => {
                        return (
                          <div
                            key={`childpage-${index}-${i}`}
                            className="row row-4 mb-3 md-lg-4"
                          >
                            <div className="col-md-12">
                              <Button
                                className="mt-n2"
                                variant="link"
                                link={`/sustainability/education-patronage/${childPage.slug}`}
                              >
                                {childPage.title}
                              </Button>
                            </div>
                          </div>
                        )
                      }
                    )}
                  </div>
                </section>
              )
            }
            return <div key={`empty-${i}`}></div>
          default:
            return <div key={`default-${i}`}>{item.name}</div>
        }
      })}
    </Layout>
  )
}
export default EducationPatronage